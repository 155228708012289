<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<!-- <div class="banner">
        <img src="../../assets/images/lifehall/banner.jpg" alt="">
      </div> -->
			<Banner :bannerList="bannerList" height="395px" :showtitle="showtitle"></Banner>
			<div class="wrap wrap1">
				<div class="page">
					<div class="title">
						核心功能
					</div>
					<div class="subhead">
						悦享品质生活
					</div>
					<div class="img-list">
						<div class="img-item">
							<img src="../../assets/images/lifehall/book.png" alt="">
							<div class="name">图书</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/kafe.png" alt="">
							<div class="name">咖啡</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/cake.png" alt="">
							<div class="name">甜品</div>
						</div>
						<div class="img-item">
							<img src="../../assets/images/lifehall/gym.png" alt="">
							<div class="name">健身</div>
						</div>
					</div>
				</div>

			</div>

			<div class="wrap wrap2">

				<div class="store-list">

					<div class="store-item1">
						<div class="page">
							<img src="../../assets/images/lifehall/jwwl.jpg" alt="">

							<div class="store-wrap">
								<div class="name">西安泾渭五路店</div>
								<div class="desc">小院生活·泾渭五路店旨为用户打造一个休闲、娱乐交互空间，生活馆提供饮品、咖啡、图书等服务项目。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										029-86039888
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一到周日 8:00-20:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市高陵区泾渭5路小院生活
									</span>
								</div>
							</div>
						</div>

					</div>

					<div class="store-item2">
						<div class="page">
							<div class="store-wrap">
								<div class="name">西安奥韵华府店</div>
								<div class="desc">小院生活·奥韵华府店服务包含饮品、图书借阅、休闲交互。恬静、广阔的空间让大家更好的体验属于自己的私人第三空间。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										029-86013666
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一到周日 9:00-20:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市高陵区昭慧路奥韵华府
									</span>
								</div>
							</div>
							<img src="../../assets/images/lifehall/ayhf.png" alt="">

						</div>
					</div>


					<div class="store-item1">
						<div class="page">
							<img src="../../assets/images/lifehall/hfgc.png" alt="">

							<div class="store-wrap">
								<div class="name">渭南宏帆广场店</div>
								<div class="desc">
									小院生活·宏帆馆服务项目涵盖读书、茶饮、甜品、精品手冲等，旨在打造家庭、工作以外的第三生活空间，以高端的品质、完美的细节打造都市人心中向往的生活高地。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										0913-2071999
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										渭南市临渭区宏帆广场A馆2层AZ-L2-10号商铺
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="store-item2">
						<div class="page">
							<div class="store-wrap">
								<div class="name">渭南万达广场店</div>
								<div class="desc">小院生活·万达馆主营读书、咖啡、茶饮、甜品等，店面风格将现代与田园完美融合，为消费者带来高品质的美好生活体验。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										0913-2071999
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										渭南市新区万达广场3层3030号商铺
									</span>
								</div>
							</div>
							<img src="../../assets/images/lifehall/wdgc.png" alt="">

						</div>
					</div>



					<div class="store-item1">
						<div class="page">
							<img src="../../assets/images/lifehall/mopark.jpg" alt="">

							<div class="store-wrap">
								<div class="name">西安MOMOPARK店</div>
								<div class="desc">小院生活·MOMOPARK店（华夫茶饼店）是小院首家亲子主题门店，产品以华夫饼、咖啡、鲜榨果汁为主，打造全新的轻业态华夫体验场馆。
								</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										18710711507
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市雁塔区小寨西路232号MOMOPARK F3
									</span>
								</div>
							</div>

						</div>
					</div>


					<!-- 	<div class="store-item2">
						<div class="page">

							<div class="store-wrap">
								<div class="name">西安小院生活共享自习室</div>
								<div class="desc">小院生活共享自习室主打海量图书免费借阅服务，小程序在线即可完成图书的搜索及借阅，室内环境温馨舒适，提供学习休闲、读书分享、艺术拓展等体验。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										18710711507
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周日 10:00-22:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市雁塔区小寨西路232号MOMOPARK3层
									</span>
								</div>
							</div>
							<img src="../../assets/images/lifehall/momo.jpg" alt="">

						</div>
					</div> -->
					<div class="store-item2 lastItem">
						<div class="page">
							<div class="store-wrap">
								<div class="name">西安正衡金融广场店</div>
								<div class="desc">小院生活·正衡金融广场店（ Y
									Cafe），是首家入驻于办公楼内的小院生活馆。店面风格以灰白配色为主基调，简约利落，安静温柔又不乏时尚。工作之余，在繁忙中停下脚步，来这里享受片刻的好时光。</div>
								<div class="mobile-wrap">
									<span class="iconfont icon-phone_light"></span>
									<span class="mobile">
										17349024234
									</span>
								</div>
								<div class="time-wrap">
									<span class="iconfont icon-time1"></span>
									<span class="time">
										周一至周五 8:00-18:00
									</span>
								</div>
								<div class="address-wrap">
									<span class="iconfont icon-location_light"></span>
									<span class="address">
										西安市长安区雁塔南路正衡金融广场A栋1F
									</span>
								</div>
							</div>
							<img src="../../assets/images/lifehall/Ycafe.jpg" alt="">
						</div>
					</div>

				</div>
			</div>
			<div class="wrap3">
				<Map class="mapbox" :storeList="storeList"></Map>
				<!-- <t-map ref="tencentMap" @nowLocation="nowLocation" :storeList="storeList2"></t-map> -->
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Map from '@/components/map'
	import tMap from "@/components/tencent-map";
	import Banner from "@/components/banner.vue"
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				bannerList: [],
				showtitle: false,
				storeList: [{
						name: "小院生活·大唐芙蓉园龙舫店",
						address: "西安市雁塔区大唐芙蓉园景区龙舫",
						lnglat: [108.971013, 34.213293],
					},
					{
						name: "小院生活·MOMOPARK华夫茶饼店",
						address: "西安市雁塔区小寨西路232号MOMOPARK3层",
						lnglat: [108.933256, 34.221806],
					},
					{
						name: "小院生活·正衡金融广场Y Cafe店",
						address: "西安市长安区雁塔南路正衡金融广场A栋1F",
						lnglat: [108.965209, 34.153392],
					},
					{
						name: "小院生活·泾渭五路店",
						address: "西安市高陵区泾渭5路小院生活",
						lnglat: [109.014027, 34.446513]
					},
					{
						name: "小院生活·奥韵华府店",
						address: "西安市高陵区昭慧路奥韵华府",
						lnglat: [109.096955, 34.520673],
					},
					{
						name: "小院生活·宏帆广场店",
						address: "渭南市临渭区宏帆广场A馆2层AZ-L2-10号商铺",
						lnglat: [109.4929440, 34.4981870],
					},
					{
						name: "小院生活·万达广场店",
						address: "渭南市新区万达广场3层3030号商铺",
						lnglat: [109.4532610, 34.5064810]
					}
				],
				storeList2: [
					{	
						marker:'marker1',
						name: "小院生活·大唐芙蓉园龙舫店",
						address: "西安市雁塔区大唐芙蓉园景区龙舫",
						lnglat: {
							lat1:34.213293,
							lat2:108.971013
						}
					},
					{
						marker:'marker2',
						name: "小院生活·MOMOPARK华夫茶饼店",
						address: "西安市雁塔区小寨西路232号MOMOPARK3层",
						lnglat: {
							lat1:34.221806,
							lat2:108.933256
						}
					},
					{
						marker:'marker3',
						name: "小院生活·正衡金融广场Y Cafe店",
						address: "西安市长安区雁塔南路正衡金融广场A栋1F",
						lnglat: {
							lat1:34.153392,
							lat2:108.965209
						}
					},
					{
						marker:'marker4',
						name: "小院生活·泾渭五路店",
						address: "西安市高陵区泾渭5路小院生活",
						lnglat: {
							lat1:34.446513,
							lat2:109.014027
						}
					},
					{
						marker:'marker5',
						name: "小院生活·奥韵华府店",
						address: "西安市高陵区昭慧路奥韵华府",
						lnglat: {
							lat1:34.520673,
							lat2:109.096955
						}
					},
					{
						marker:'marker6',
						name: "小院生活·宏帆广场店",
						address: "渭南市临渭区宏帆广场A馆2层AZ-L2-10号商铺",
						lnglat: {
							lat1:34.4981870,
							lat2:109.4929440
						}
					},
					{
						marker:'marker7',
						name: "小院生活·万达广场店",
						address: "渭南市新区万达广场3层3030号商铺",
						lnglat: {
							lat1:34.5064810,
							lat2:109.4532610
						}
					}
				]
			}
		},
		components: {
			Header2022,
			Footer2022,
			Map,
			Banner,
			tMap,
		},
		computed: {
			...mapState(['ocApi'])
		},
		created() {
			this.getBanner();
		},
		methods: {
			getBanner: function() {
				let that = this;
				that.$axios.get(that.ocApi + 'book/banner', {
						params: {
							'name': 'pc_product_lifehall',
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							that.bannerList = res.data.data.images
						} else {
							that.$message.error(res.data.message);
						}
					})
			},
			nowLocation(obj) {
				this.$set(this.dataForm, "actLng", obj.lng);
				this.$set(this.dataForm, "actLat", obj.lat);
			},
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		width: 100%;
		background-color: #fff;
		padding-top: 64px;

		// .banner>img {
		// 	width: 100%;
		// }

		.wrap {
			color: #303233;
		}

		.wrap1 {
			padding: 100px 0 43px;

			.title {
				font-size: 40px;
				line-height: 50px;
				font-weight: 900;
				text-align: center;
			}

			.subhead {
				font-size: 24px;
				font-weight: 400;
				line-height: 50px;
				margin-top: 22px;
				text-align: center;
			}

			.img-list {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 60px;

				.img-item {
					width: 279px;
					position: relative;

					>img {
						width: 100%;
					}

					.name {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 35px;
						line-height: 58px;
						color: #ffffff;
					}
				}
			}
		}

		.wrap2 {
			.store-list {
				.store-item1 {
					background-color: #fff;
					padding: 90px 0;
				}

				.store-item2 {
					background-color: #f6f6f6;
				}

				.lastItem {
					margin-bottom: 90px;
				}

				.page {
					display: flex;
					justify-content: space-between;
					align-items: center;

					>img {
						width: 700px;
					}

					.store-wrap {
						width: 430px;

						.name {
							font-size: 40px;
							font-weight: 900;
							line-height: 50px;
						}

						.desc {
							font-size: 16px;
							font-weight: 400;
							line-height: 32px;
							margin-top: 24px;
							padding-bottom: 21px;
							border-bottom: 1px solid #dddddd;
						}

						.mobile-wrap {
							font-size: 16px;
							line-height: 16px;
							margin-top: 25px;
						}

						.time-wrap {
							font-size: 16px;
							line-height: 16px;
							margin-top: 10px;

							.iconfont {
								font-size: 14px;
							}
						}

						.address-wrap {
							font-size: 16px;
							line-height: 16px;
							margin-top: 10px;
						}

						.iconfont {
							font-size: 16px;
							color: #000000;
						}
					}
				}
			}
		}

		.wrap3 {
			.mapbox {
				width: 100%;
				height: 700px;
			}
		}
	}
</style>
