<template>
	<div id="container"></div>
</template>

<script>
	// import TMap from "TMap";
	export default {
		// name: "tencentMap",
		props: {
			storeList: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
		data() {
			return {
				map: null, // 地图对象
			}
		},
		watch: {
			storeList: function() {
				this.initMap()
			}
		},
		mounted() {
			this.initMap()
		},
		methods: {
			initMap() {
				// 定义map变量，调用 TMap.Map() 构造函数创建地图
				var map = new TMap.Map('container', {
					center: new TMap.LatLng(34.507057, 109.453491), //设置地图中心点坐标
					zoom: 11, //设置地图缩放级别
					viewMode: '2D'
				})
				var storeList = this.storeList;
				for (const item of storeList) {
					var marker = new TMap.MultiMarker({
						// id: 'marker-layer',
						map: map,
						styles: {
							"marker": new TMap.MarkerStyle({
								"width": 25,
								"height": 35,
								"anchor": {
									x: 16,
									y: 32
								},
							})
						},
						geometries: [{
							"id": item.marker,
							"styleId": 'marker',
							"position": new TMap.LatLng(item.lnglat.lat1, item.lnglat.lat2),
							"properties": {
								"title": item.name
							},
						}],
					});
					var aaa=`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${item.lnglat.lat1, item.lnglat.lat2}&title:${item.name}&addr:${item.address}&key=473b8741a253a1d7c855cc1819422e09&referer=myapp`
					// marker.on("click", eventClick)
					// marker.emit('click', {
					// 	target: marker
					// });
					var infoWindow = new TMap.InfoWindow({
						map: map,
						position: new TMap.LatLng(item.lnglat.lat1, item.lnglat.lat2),
						offset: {
							x: 0,
							y: -30
						}, //设置信息窗相对position偏移像素
						content: `<div style="background:#fff">
									<div style="padding:0 20px 20px;">
									  <div style="font-size:20px">${item.name}</div>
									  <div style="font-size:14px;margin-top:20px;">${item.address}</div>
									</div>
								  </div>`
					});
					infoWindow.close(); //初始关闭信息窗关闭
					//监听标注点击事件
					marker.on("click", function(evt) {
						//设置infoWindow
						// infoWindow.open(map, evt.target.getPosition());
						infoWindow.open(); //打开信息窗
						// window.open(url);
						infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
						infoWindow.setContent(infoWindow.content); //设置信息窗内容
					});
				}
			}
		},
	}
</script>

<style>
	#container {
		width: 100%;
		height: 700px;
		margin-top: 50px;
	}
</style>
